import React from "react"
import Layout from "../../components/layout"
import TextSection from "../../components/project/intro/text-section"
import ProjectDetails from "../../components/project/intro/project-details"
import Spread from "../../components/project/spread"
import {
  Hero,
  Image,
  ImageCaption,
  Close,
  ImagePlacementIcon,
  projectWrapper,
  SpreadText,
  SpreadImageGrid,
} from "../../styles/project-styles"
import SpreadTitle from "../../components/project/spread-title"
import CaptionLeft from "../../components/project/caption-left"
import Understand from "../../components/project/process/understand"
import Design from "../../components/project/process/design"
import Prototype from "../../components/project/process/prototype"
import Img from "gatsby-image"
import SmoothScroll from "../../components/smooth-scroll"
import { ImgPlacement } from "../../assets/svg/social-icons"
import { motion } from "framer-motion"

const ColorInspiration = ({ data }) => {
  const {
    colorHero,
    colorBukcets,
    womanColorPaper,
    iceCream,
    colorWheel,
    colorFeed,
    temple,
  } = data
  return (
    <motion.div
      variants={projectWrapper}
      initial="open"
      animate="open"
      exit="close"
    >
      <Close to="/work/" />
      <Layout project>
        {/* Hero */}
        <Spread fluid>
          <Hero
            gridRowEnd={3}
            imgUrl={colorHero.childImageSharp.fluid.src}
            aspectRatio={1920 / 1080}
          />
        </Spread>

        <Spread>
          <SpreadText colStart={8} colEnd="span 5" even>
            <TextSection
              title="Challenge"
              text="Finding the right color palette for your artwork can be challenging. As novice designers we often have a single color in mind or a quality we want to convey such as “warm”, “cold”, etc. However, most of us lack the knowledge of color theory necessary to materialize our vision into a color palette."
              style={{ marginBottom: "3rem" }}
            />
            <TextSection
              title={"Outcome"}
              text="The outcome of this exploratory project was a color palette recommendation system that responds to user actions such as active color change, text search, and the placement of images on the canvas."
            />
            <ProjectDetails
              type={"software, professional"}
              contributions={"software engineering"}
              rowStart={3}
              alignSelf="end"
            />
          </SpreadText>

          <SpreadImageGrid colStart={1} colEnd="span 7" rowStart={1}>
            <Image colEnd="span 6" rowStart={1} rowEnd={"span 3"}>
              <Img fluid={colorBukcets.childImageSharp.fluid} />
            </Image>
            <Image colStart={1} colEnd="span 3" rowStart={4} rowEnd="span 3">
              <Img fluid={womanColorPaper.childImageSharp.fluid} />
              <ImageCaption>source: unsplash</ImageCaption>
            </Image>
            <Image colStart={4} colEnd="span 3" rowStart={4} rowEnd="span 3">
              <Img fluid={iceCream.childImageSharp.fluid} />
            </Image>
          </SpreadImageGrid>
        </Spread>

        <Spread>
          <SpreadTitle
            text="The Process"
            colStart={1}
            colEnd="span 4"
            rowStart={1}
            rowEnd="span 1"
          />

          <SpreadText colEnd="span 4">
            <Understand text="Based on user-studies conducted by the Product Managers and Designers on my team, the vision for this project was to create a feed of color palette recommendations inside our popular graphics editor. During this phase I scoped out the techincal feasibility." />
            <Design text="Recommendations are received based on three triggers: 1) change of active color, 2) placement of raster images on the canvas, 3) in-app search. Trigger 1 returns palettes that include the active color. When raster images are detected, the objects  in the image are extracted (“house”, “mountain”, “grass”, etc.) and  used to search for relevant palettes. When the user performs a keyword search in the panel (“forest“, “ocean”, “warm”, “cold”), the most relevant color palettes are returned." />
            <Prototype text="The prototype was built as a custom panel inside the graphics editor using modern JavaScript frameworks such as React.js and Redux. Search queries were fulfilled by an elasticsearch cluster on the backend." />
          </SpreadText>

          <SpreadImageGrid colStart={6} colEnd="span 7">
            <Image
              colStart={1}
              colEnd="span 3"
              rowStart={1}
              rowEnd="span 3"
              caption
            >
              <Img
                // objectPosition="center center"
                fluid={colorWheel.childImageSharp.fluid}
                imgStyle={{
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
              />
              <ImageCaption>trigger 1: active color change</ImageCaption>
            </Image>

            <Image colStart={1} colEnd="span 3" rowStart={4} rowEnd="span 3">
              <ImagePlacementIcon>
                <ImgPlacement />
              </ImagePlacementIcon>
              <Img fluid={temple.childImageSharp.fluid} />
              <ImageCaption>
                trigger 2: placement of raster image on the canvas
              </ImageCaption>
            </Image>
            <Image
              colStart={4}
              colEnd="span 4"
              rowStart={1}
              rowEnd="span 3"
              alignSelf={"start"}
            >
              <ImageCaption>color recommendation feed</ImageCaption>
              <Img
                objectFit="contain"
                fluid={colorFeed.childImageSharp.fluid}
              />
            </Image>
          </SpreadImageGrid>
        </Spread>
      </Layout>
    </motion.div>
  )
}

export default ColorInspiration

export const pageQuery = graphql`
  query {
    colorHero: file(
      relativePath: { eq: "color-inspiration/color-insp-hero-2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    colorBukcets: file(
      relativePath: { eq: "color-inspiration/color-buckets.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    womanColorPaper: file(
      relativePath: { eq: "color-inspiration/woman-color-paper.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iceCream: file(relativePath: { eq: "color-inspiration/ice-cream.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    colorWheel: file(
      relativePath: { eq: "color-inspiration/color-wheel-mono.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    colorFeed: file(relativePath: { eq: "color-inspiration/color-feed.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    temple: file(relativePath: { eq: "color-inspiration/temple.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
